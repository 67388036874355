import React, { ReactNode, useEffect } from 'react';
import { Col, Row, Space } from 'antd';
import { colorType, typographyType } from '../../helpers';
import { useTranslator } from '@opyn/utils';
import { Typography } from '../index';
import Style from './style';
import poweredByOpyn from '../../assets/images/wallpapers/powered_byOpyn.png';
import Linkedin from '../../assets/icons/social_media/linkedin';
import Instagram from '../../assets/icons/social_media/instagram';
import Facebook from '../../assets/icons/social_media/facebook';
import { globalSettings } from '../../constants/settings';
import { COOKIEHUB_GLOBAL_VAR } from '../../settings';
import { useLocation } from 'react-router-dom';

const footerLinks: {
  labelKey: string;
  url?: string;
  action?: () => void;
}[] = [
  {
    labelKey: 'privacyPolicy.label',
    url: 'https://www.opyn.eu/privacy-policy',
  },
  {
    labelKey: 'cookiePolicyManagement.label',
    action: () => {
      (window as any)[COOKIEHUB_GLOBAL_VAR]?.openSettings();
    },
  },
  {
    labelKey: 'paymentServiceDirective.label',
    url: 'https://www.opyn.eu/psd2',
  },
  {
    labelKey: 'security.label',
    url: 'https://www.opyn.eu/sicurezza',
  },
  {
    labelKey: 'transparency.label',
    url: 'https://www.opyn.eu/trasparenza',
  },
  {
    labelKey: 'complaints.label',
    url: 'https://www.opyn.eu/reclami',
  },
];

interface Props {
  style?: React.CSSProperties;
  children?: ReactNode;
}

export const socialLinks = [
  {
    component: Linkedin,
    url: 'https://www.linkedin.com/company/opyn-eu/',
  },
  {
    component: Instagram,
    url: 'https://www.instagram.com/opyn.eu/',
  },
  {
    component: Facebook,
    url: 'https://www.facebook.com/Opyn.eu',
  },
];

export const Footer = ({ style, children }: Props) => {
  const translator = useTranslator();
  const location = useLocation();
  const languages = globalSettings.cultures;
  const [isCookiehub, setIsCookihub] = React.useState(
    !!(window as any)[COOKIEHUB_GLOBAL_VAR]
  );

  useEffect(() => {
    setIsCookihub(!!(window as any)[COOKIEHUB_GLOBAL_VAR]);
  }, [location]);

  return (
    <Style style={style}>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography colorType={colorType.light} variant={typographyType.span}>
            © {new Date().getFullYear()} OPYN
            {footerLinks.map((link) => {
              if (link?.action === undefined || isCookiehub) {
                return (
                  <React.Fragment key={link.labelKey}>
                    &nbsp;<b>&middot;</b>&nbsp;
                    {link?.url ? (
                      <a href={link.url} target="_blank" rel="noreferrer">
                        {translator.translate(link.labelKey)}
                      </a>
                    ) : link?.action ? (
                      <a onClick={link?.action}>
                        {translator.translate(link.labelKey)}
                      </a>
                    ) : null}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </Typography>
        </Col>
        <Col>
          <Typography colorType={colorType.light} variant={typographyType.span}>
            {languages &&
              languages.map((l, index: number) => {
                return (
                  <React.Fragment key={l.id}>
                    {index !== 0 && (
                      <>
                        &nbsp;<b>&middot;</b>
                      </>
                    )}
                    &nbsp;
                    <a
                      href="#"
                      data-cy="chooseLanguage"
                      className={`${translator.language === l.code ? 'active' : ''}`}
                      onClick={() => {
                        translator.changeLanguage(l.code);
                      }}
                    >
                      {l.name}
                    </a>
                  </React.Fragment>
                );
              })}
          </Typography>
        </Col>
        <Col>
          <Row>
            <Space>
              {socialLinks.map((link: any, i) => {
                return (
                  <div key={i}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      {<link.component />}
                    </a>
                  </div>
                );
              })}
            </Space>
          </Row>
        </Col>
        <Col>
          <Row>
            <Space>
              {children}
              <img height={40} src={poweredByOpyn} alt="img" />
            </Space>
          </Row>
        </Col>
      </Row>
    </Style>
  );
};
